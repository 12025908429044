<template>
    <div>
        <div class="title-page flex justify-between text-center">
            <div>
                <feather-icon icon="FileIcon" svgClasses="h-4 w-4"></feather-icon>
                <h3 class="font-semibold mb-2 ml-3" style="display: inline-block; font-size: 1.125rem;">{{ $t('termsCondition') }}</h3>
            </div>
        </div>
        <div class="line"></div>
        <p v-html="terms_and_conditions.page.content"></p>
    </div>
</template>

<script>
import { mapActions } from "vuex";
import { mapGetters } from 'vuex'

export default {
    computed: {
        ...mapGetters('dataList', ['terms_and_conditions']),
    },
    methods: {
        ...mapActions('dataList', ['fetchTermsAndCondition']),
    },
    created(){
        this.fetchTermsAndCondition()
    }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/vuexy/_variables.scss';

.title-page{
    margin-bottom: 1.3rem;
    margin-top: 1.3rem;
}
.line{
    border-bottom: 0.03125rem solid #70707061;
    opacity: 50%;
    margin-bottom: 1.3rem;
}
P{
    color: #212121;
    font-weight: 600;
    line-height: 2.5rem;
}
</style>